import PropTypes from "prop-types"
import React from "react"

import { Box, Link } from "@chakra-ui/core"

import Image from "./image"

const Header = ({ siteTitle }) => (
  <header>
    <Box p={6} bg="white">
      <Link>
        <Image>
          {siteTitle}
        </Image>
      </Link>
    </Box>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
