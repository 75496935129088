/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Box } from "@chakra-ui/core"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulFlatblock(filter: {slug: {eq: "footer"}, node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            content {
              json
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box
        bg="white"
        maxW={960}
        p="0px 1.0875rem 1.45rem"
        m="0 auto"
      >
        <main>{children}</main>
        <footer>
        {documentToReactComponents(
          data.allContentfulFlatblock.edges[0].node.content.json
        )}
        </footer>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
